import { API } from "aws-amplify";

export const endPoint = process.env.GATSBY_API_URL;
export const authConfig = {
  mandatorySignIn: true,
  region: "ap-southeast-2",
  userPoolId: process.env.GATSBY_userPoolId,
  identityPoolId: process.env.GATSBY_identityPoolId,
  userPoolWebClientId: process.env.GATSBY_userPoolWebClientId,
};
export const oauthConfig = {
  domain: process.env.GATSBY_domain,
  redirectSignIn: process.env.GATSBY_redirectSignIn,
  redirectSignOut: process.env.GATSBY_redirectSignOut,
  responseType: "code",
};

export const createUser = (body: any) =>
  API.post("baseUrl", "/users/signup", { body });
export const deleteUAccount = async () => {
  return API.post("baseUrl", "/users/deleteAccount", {});
};
export const changeUserPhoneNumber = (body: any) =>
  API.post("baseUrl", "/users/changeUserPhoneNumber", { body });
export const verifyPhoneNumber = (body: any) =>
  API.post("baseUrl", "/users/verifyPhoneNumber", { body });
export const getProfile = async () => {
  return API.get("baseUrl", "/users/profile", {});
};

export const getUserStatus = async () => {
  return API.get("baseUrl", "/users/getUserStatus", {});
};
export const getConsents = async () => {
  return API.get("baseUrl", "/consents", {});
};

export const checkConsents = async () => {
  return API.get("baseUrl", "/consents/check", {});
};

export const getCode = () => {
  return API.get("baseUrl", "/employer", {});
};
export const checkCode = (code: string) => {
  return API.get("baseUrl", `/employer/${code}`, {});
};
export const updateProfile = (body: any) =>
  API.post("baseUrl", `/users/update`, { body });
export const addFCMToken = (body: any) =>
  API.post("baseUrl", `/users/addFcm`, { body });

export const getCategories = (page = 1) =>
  API.get("baseUrl", `/categories?page=${page}`, {});
export const getExcludedCategories = (page = 1) =>
  API.get("baseUrl", `/categories/excluded?page=${page}`, {});
export const getExcludedCategoriesDemo = (categoryId: string) =>
  API.get("baseUrl", `/categories/getSubDivisionByCode/${categoryId}`, {});
export const getPayments = (page = 1) =>
  API.get("baseUrl", `/payments?page=${page}`, {});
export const getAccount = (page: number): any =>
  API.get("baseUrl", `/account?page=${page}`, {});
export const getAccountDetails = (id: string) =>
  API.get("baseUrl", `/account/${id}`, {});

export const getPaymentProduct = () =>
  API.get("baseUrl", "/payments/products", {});
export const getStripeUrl = (body: { priceId: string }) =>
  API.post("baseUrl", "/payments/create", { body });
export const getPaymentStatus = (id: string) =>
  API.get("baseUrl", `/payments/status/${id}`, {});

export const getInstallmentSchedules = ({
  startDate,
  endDate,
  id = "",
  idName = "payoutId",
}: any) =>
  API.get(
    "baseUrl",
    `/installmentSchedules?page=${-1}&startDate=${startDate}&endDate=${endDate}&${idName}=${id}`,
    {}
  );
export const getInstallments = ({ startDate, endDate, overdue = "" }: any) =>
  API.get(
    "baseUrl",
    `/installments?page=${-1}&startDate=${startDate}&endDate=${endDate}&overdue=${overdue}`,
    {}
  );

export const getInstallmentsDates = ({ startDate, endDate, status }: any) =>
  API.get(
    "baseUrl",
    `/installmentsDates?page=${1}&startDate=${startDate}&endDate=${endDate}&status=${status}`,
    {}
  );

export const checkIdentity = (id: string) =>
  API.post("baseUrl", "/users/check-identity-verification", {
    body: { transactionId: id },
  });

export const checkValidation = (id: string) =>
  API.post("baseUrl", `/payments/checkAgreementValidation`, { body: { id } });

export const setBankAccount = (linkedBankAccountId: string) =>
  API.post("baseUrl", "/account/setBankAccountForDisbursement", {
    body: { linkedBankAccountId },
  });

export const payToAgreement = (body: payToAgreement) =>
  API.post("baseUrl", "/payments/payToAgreementValidation", {
    body,
    timeout: 60000,
  });
export const convertToInstallment = (body: any) =>
  API.post("baseUrl", `/transactions/convertToInstallment`, {
    body,
    timeout: 300000,
  });

export const getAllTransaction = (body: {
  page: number;
  accountIds?: string[];
  isEligible?: boolean;
}) => {
  return API.post("baseUrl", `/transactions`, { body });
};
export const syncNewTransactions = (body: any) => {
  return API.post("baseUrl", `/syncTransactions`, { body });
};

export const getAgreement = (id: string) =>
  API.get("baseUrl", `/agreements/${id}`, {});

export const getAllAgreements = (page: number) =>
  API.get("baseUrl", `/agreements?page=${page}`, {});

export const getAccessToken = () =>
  API.get("baseUrl", "/users/get-consent-url", {});
export const getConsentStatus = () =>
  API.post("baseUrl", "/users/update-consent", { timeout: 60000 });
export const removeAccount = (accountId: string) =>
  API.post("baseUrl", "/account/remove", { body: { accountId } });

export const getVerifyIdUrl = () =>
  API.get("baseUrl", "/users/get-identity-verification-url", {});

export const getNotifications = (
  page: number = 1,
  unread?: boolean | null,
  type?: string[]
) => {
  const queryString = unread
    ? `/notifications?page=${page}&unread=${unread}&type=${type}`
    : `/notifications?page=${page}&type=${type}`;
  return API.get("baseUrl", queryString, {});
};
export const readNotifications = async (notificationIds: any[]) => {
  return API.post("baseUrl", "/notifications/read", {
    body: { notificationIds: notificationIds },
  });
};
interface payToAgreement {
  bsb: string;
  accountNumber: string;
  frequency: string;
  plan: string;
  amount: string;
  startDate: Date;
}

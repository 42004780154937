import { Amplify } from "aws-amplify";
import { endPoint, authConfig, oauthConfig } from "./src/API/api";

Amplify.configure({
  Auth: {
    ...authConfig,
  },
  API: {
    endpoints: [
      {
        name: "baseUrl",
        endpoint: endPoint,
        region: "ap-southeast-2",
      },
    ],
  },
  oauth: {
    ...oauthConfig,
    scope: ["phone", "email", "profile", "openid"],
  },
});
// window.LOG_LEVEL = 'DEBUG';

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-linking-tsx": () => import("./../../../src/pages/account-linking.tsx" /* webpackChunkName: "component---src-pages-account-linking-tsx" */),
  "component---src-pages-change-password-tsx": () => import("./../../../src/pages/change-password.tsx" /* webpackChunkName: "component---src-pages-change-password-tsx" */),
  "component---src-pages-complete-payment-tsx": () => import("./../../../src/pages/complete-payment.tsx" /* webpackChunkName: "component---src-pages-complete-payment-tsx" */),
  "component---src-pages-confirm-link-another-tsx": () => import("./../../../src/pages/confirm-link-another.tsx" /* webpackChunkName: "component---src-pages-confirm-link-another-tsx" */),
  "component---src-pages-confirm-unlink-tsx": () => import("./../../../src/pages/confirm-unlink.tsx" /* webpackChunkName: "component---src-pages-confirm-unlink-tsx" */),
  "component---src-pages-confirmation-linking-tsx": () => import("./../../../src/pages/confirmation-linking.tsx" /* webpackChunkName: "component---src-pages-confirmation-linking-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-tsx": () => import("./../../../src/pages/legal.tsx" /* webpackChunkName: "component---src-pages-legal-tsx" */),
  "component---src-pages-manage-consent-tsx": () => import("./../../../src/pages/manage-consent.tsx" /* webpackChunkName: "component---src-pages-manage-consent-tsx" */),
  "component---src-pages-manage-linked-accounts-tsx": () => import("./../../../src/pages/manage-linked-accounts.tsx" /* webpackChunkName: "component---src-pages-manage-linked-accounts-tsx" */),
  "component---src-pages-payment-loading-tsx": () => import("./../../../src/pages/payment-loading.tsx" /* webpackChunkName: "component---src-pages-payment-loading-tsx" */),
  "component---src-pages-personal-details-tsx": () => import("./../../../src/pages/personal-details.tsx" /* webpackChunkName: "component---src-pages-personal-details-tsx" */),
  "component---src-pages-profile-tsx": () => import("./../../../src/pages/profile.tsx" /* webpackChunkName: "component---src-pages-profile-tsx" */),
  "component---src-pages-report-generated-tsx": () => import("./../../../src/pages/report-generated.tsx" /* webpackChunkName: "component---src-pages-report-generated-tsx" */),
  "component---src-pages-sign-in-tsx": () => import("./../../../src/pages/sign-in.tsx" /* webpackChunkName: "component---src-pages-sign-in-tsx" */),
  "component---src-pages-sign-up-tsx": () => import("./../../../src/pages/sign-up.tsx" /* webpackChunkName: "component---src-pages-sign-up-tsx" */),
  "component---src-pages-status-result-tsx": () => import("./../../../src/pages/status-result.tsx" /* webpackChunkName: "component---src-pages-status-result-tsx" */),
  "component---src-pages-terms-condition-tsx": () => import("./../../../src/pages/terms-condition.tsx" /* webpackChunkName: "component---src-pages-terms-condition-tsx" */),
  "component---src-pages-unlink-account-tsx": () => import("./../../../src/pages/unlink-account.tsx" /* webpackChunkName: "component---src-pages-unlink-account-tsx" */),
  "component---src-pages-update-number-otp-tsx": () => import("./../../../src/pages/update-number-otp.tsx" /* webpackChunkName: "component---src-pages-update-number-otp-tsx" */),
  "component---src-pages-update-phone-tsx": () => import("./../../../src/pages/update-phone.tsx" /* webpackChunkName: "component---src-pages-update-phone-tsx" */),
  "component---src-pages-verify-number-tsx": () => import("./../../../src/pages/verify-number.tsx" /* webpackChunkName: "component---src-pages-verify-number-tsx" */),
  "component---src-pages-verify-otp-tsx": () => import("./../../../src/pages/verify-otp.tsx" /* webpackChunkName: "component---src-pages-verify-otp-tsx" */)
}

